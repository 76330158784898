import Api from './api.service'

export default {
  getList (params) {
    return Api().get('/project', { params })
  },
  get (id) {
    return Api().get(`/project/${id}`)
  },
  store (payload) {
    return Api().post('project', payload)
  },
  update (id, payload) {
    return Api().post(`project/${id}`, payload)
  },
  delete (id) {
    return Api().delete(`project/${id}`)
  },
  storeSignature(id, payload) {
    return Api().post(`project/signature/${id}`, payload)
  },
  storeInsuranceImage(id, payload) {
    return Api().post(`project/measurement-image/${id}`, payload)
  },
  sendMail(id, documentType, payload) {
    return Api().post(`mail/${documentType}/${id}`, payload)
  },
  createOffer(id) {
    return Api().post(`project/create-offer/${id}`)
  }
}
