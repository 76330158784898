<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div data-uk-sticky="offset:52;media: @m" class="uk-background-default breadcrumb" style="padding: 15px 35px; border-bottom: 1px solid rgb(229, 229, 229)">
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-flex uk-flex-middle">
            <h1 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle" style="color: rgb(51, 51, 51)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right">
                <path
                  d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                />
              </svg>
              Benutzer
            </h1>
          </div>
          <div>
            <button class="uk-button uk-button-primary uk-button-small uk-border-rounded" @click.prevent="openCreateUserModal(null)">
              <span uk-icon="plus-circle"></span>
              Neuen Benutzer anlegen
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-margin-top">
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <div class="uk-width-1-1">
          <div class="uk-card uk-card-default uk-margin-large-bottom">
            <div class="uk-card-body uk-padding-remove">
              <table class="uk-table uk-table-hover uk-table-striped uk-table-responsive" v-if="users.length">
                <thead>
                  <tr>
                    <th class="uk-table-expand">Name</th>
                    <th class="uk-table-expand">E-Mail</th>
                    <th class="uk-table-shrink">Rolle</th>
                    <th class="uk-table-shrink uk-text-center">Status</th>
                    <th style="width: 60px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="uk-visible-toggle" v-for="(user, i) in users" :key="i">
                    <td>
                      {{ user.name }}
                      <br />
                      <span class="uk-text-muted uk-text-small"> Projekt(e): {{ renderProjects(user.projects) }} </span>
                    </td>
                    <td>
                      {{ user.email }}
                    </td>
                    <td>
                      <span class="uk-label uk-background-secondary uk-width-1-1 uk-text-center">{{ user.rolesFormatted[0] }}</span>
                    </td>
                    <td class="uk-text-center">
                      <span v-if="user.active" class="uk-text-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span v-else class="uk-text-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </td>
                    <td class="uk-text-right">
                      <a class="uk-icon-link uk-margin-small-right uk-text-primary" data-uk-icon="pencil" @click.prevent="openCreateUserModal(user)"> </a>
                      <a class="uk-icon-link uk-text-danger" data-uk-icon="trash" href="#" @click.prevent="remove(user.id)"> </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="meta && meta.pagination.total_pages > 1">
                <pagination :pagination="meta.pagination"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateUserModal ref="createUserModal" v-model="selectedUser" @created="getUsers" />
    </div>
  </div>
</template>
<script>
import UserService from '@/services/user.service.js'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import CreateUserModal from '@/components/CreateUserModal'
import Pagination from '@/components/Pagination'
export default {
  data() {
    return {
      users: [],
      checked: [],
      allSelected: false,
      meta: null,
      user: null,
      selectedUser: null,
    }
  },
  components: { CreateUserModal, Pagination },
  mounted() {
    this.getUsers()
  },
  watch: {
    '$route.query': {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getUsers() {
      UserService.getList({ ...this.$route.query }).then((response) => {
        this.users = response.data
      })
    },
    openCreateUserModal(user) {
      if (user) {
        const projects = user.projects.map((project) => {
          return project.id
        })
        this.selectedUser = {
          uuid: user.uuid,
          name: user.name,
          email: user.email,
          projects: projects,
          roles: user.roles,
        }
      } else {
        this.selectedUser = {
          uuid: null,
          name: '',
          email: '',
          projects: [],
          roles: [],
        }
      }
      this.$refs.createUserModal.open()
    },
    renderProjects(projects) {
      return projects
        .map((p) => {
          return p.number
        })
        .join(', ')
    },
    remove(id) {
      const $this = this
      this.$uikit.modal
        .confirm('Messtechniker löschen?', {
          labels: {
            cancel: 'Abbrechen',
            ok: 'Löschen',
          },
        })
        .then(
          function () {
            UserService.delete(id)
              .then(() => {
                iziToast.success({
                  title: 'OK',
                  message: 'Messtechniker gelöscht!',
                  timeout: 3000,
                  position: 'bottomRight',
                  transitionIn: 'fadeInLeft',
                })
                $this.getUsers()
              })
              .catch(() => {
                iziToast.error({
                  title: 'Fehler',
                  message: 'Messtechniker wird in einem Projekt verwendet!',
                  timeout: 3000,
                  position: 'bottomRight',
                  transitionIn: 'fadeInLeft',
                })
              })
          },
          function () {
            console.log('Rejected.')
          }
        )
    },
  },
}
</script>
