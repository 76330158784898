<template>
  <modal ref="modal" large>
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-header">
      <div class="uk-h3">Benutzer</div>
    </div>
    <div class="uk-modal-body" uk-overflow-auto>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="uk-grid uk-grid-small" data-uk-grid>
          <div class="uk-width-1-2@m">
            <div class="uk-margin">
              <label class="uk-form-label">Name*</label>
              <div class="uk-form-controls">
                <ValidationProvider :rules="'required'" name="name" v-slot="{ errors }">
                  <input class="uk-input" type="text" v-model="value.name" :class="{ 'uk-form-danger': errors[0] }" />
                </ValidationProvider>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">Passwort (min. 8 Zeichen)*</label>
              <div class="uk-form-controls">
                <ValidationProvider :rules="'min:8'" name="password" v-slot="{ errors }">
                  <input class="uk-input" type="password" name="password" v-model="password" :class="{ 'uk-form-danger': errors[0] }" />
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@m">
            <div class="uk-margin">
              <label class="uk-form-label">E-Mail*</label>
              <div class="uk-form-controls">
                <ValidationProvider :rules="'required|email'" name="email" v-slot="{ errors }">
                  <input class="uk-input" type="text" v-model="value.email" :class="{ 'uk-form-danger': errors[0] }" />
                </ValidationProvider>
              </div>
            </div>
            <div class="uk-margin" v-if="password">
              <label class="uk-form-label">Passwort wiederholen</label>
              <div class="uk-form-controls">
                <ValidationProvider :rules="'required:password|min:8|confirmed:password'" name="password_confirmation" v-slot="{ errors }">
                  <input class="uk-input" type="password" name="password_confirmation" v-model="passwordConfirmation" :class="{ 'uk-form-danger': errors[0] }" />
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1" v-if="value.id">
            <p class="uk-text-small">Geben Sie das Passwort bitte nur ein, wenn das bisherige geändert werden soll. Das neue Passwort muss mindestens 8 Zeichen lang sein.</p>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Rolle*</label>
            <div class="uk-margin-small">
              <ValidationProvider :rules="'required'" name="roles" v-slot="{ errors }">
                <div class="uk-grid-small uk-child-width-auto uk-grid">
                  <label :class="{ 'uk-form-danger': errors[0] }">
                    <input class="uk-checkbox" type="checkbox" value="ROLE_EMPLOYEE" v-model="value.roles" />
                    Mitarbeiter
                  </label>
                  <label :class="{ 'uk-form-danger': errors[0] }">
                    <input class="uk-checkbox" type="checkbox" value="ROLE_EXTERNAL" v-model="value.roles" />
                    Externer Benutzer
                  </label>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Projekte*</label>
            <div class="uk-margin-small">
              <ValidationProvider :rules="'required'" name="projects" v-slot="{ errors }">
                <div class="uk-grid-collapse uk-child-width-1-3@m uk-grid" data-uk-grid>
                  <div v-for="(chunks, i) in chunkedProjects" :key="i">
                    <div v-for="project in chunks" :key="project.id">
                      <label :class="{ 'uk-form-danger': errors[0] }">
                        <input class="uk-checkbox" type="checkbox" :value="project.id" v-model="value.projects" /> {{ project.number }} {{ project.details.data.lastname }}
                        {{ project.details.data.city }}
                      </label>
                    </div>
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-1-1">
            <div class="uk-text-right">
              <button :disabled="sending" class="uk-button uk-button-primary uk-button-small" @click.prevent="handleSubmit(createUser)">Speichern</button>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, confirmed, email, min } from 'vee-validate/dist/rules'
import ProjectService from '@/services/project.service.js'
import UserService from '@/services/user.service.js'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'

extend('required', {
  ...required,
  message: 'Pflichtfeld',
})

extend('confirmed', {
  ...confirmed,
})

extend('email', {
  ...email,
})

extend('min', {
  ...min,
})

export default {
  props: {
    value: {
      required: true,
    },
  },
  components: { Modal, ValidationProvider, ValidationObserver },
  data() {
    return {
      sending: false,
      projects: null,
      password: '',
      passwordConfirmation: '',
    }
  },
  mounted() {
    this.getProjects()
  },
  computed: {
    chunkedProjects() {
      return this.arrayChunk(this.projects, this.projects.length / 3)
    },
  },
  methods: {
    open() {
      this.$refs.modal.open()
    },
    getProjects() {
      ProjectService.getList({ ...this.$route.query }).then((response) => {
        this.projects = response.data.data
      })
    },
    async createUser() {
      this.sending = true
      const payload = Object.assign({}, this.value, { password: this.password })
      let message
      if (this.value.uuid) {
        await UserService.update(payload)
        message = 'Benutzer aktualisiert!'
      } else {
        await UserService.create(payload)
        message = 'Benutzer angelegt!'
      }
      this.password = ''
      this.passwordConfirmation = ''
      this.$refs.modal.close()
      this.$emit('created')
      this.sending = false
      iziToast.success({
        title: 'OK',
        message: message,
        timeout: 3000,
        position: 'topCenter',
      })
      this.form = Object.assign(
        {},
        {
          name: '',
          email: '',
          projects: [],
          roles: [],
        }
      )
    },
    arrayChunk(arr, chunkSize) {
      const res = []
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize)
        res.push(chunk)
      }
      return res
    },
  },
}
</script>
